<template>
  <div class="container max-w-md px-4 pt-4 mx-auto overflow-x-hidden">
    <!-- <div class="flex items-center justify-between w-full gap-4 mt-8">
      <button class="flex-1 py-2 bg-gray-400 bg-opacity-50 rounded-md dark:bg-gray-700 hover:bg-opacity-100 focus:outline-none">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
      </button>
      <button @click="addModal = true" class="flex items-center justify-center flex-1 py-2 text-white rounded-md focus:outline-none bg-primary-500 dark:bg-primary-600 hover:bg-primary-600 dark:hover:bg-primary-500">
        <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
        Neu
      </button>
    </div> -->
    <div :class="['relative w-full transition-all duration-100 transform border-b border-gray-600', addFocus || add.value ? 'bg-gray-700 rounded-md' : 'bg-transparent']">
      <svg class="absolute left-0 w-4 h-4 ml-2 text-gray-400 transform -translate-y-1/2 top-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
      <input v-model="add.value" @keyup.enter="addRecord()" @focus="addFocus = true" @blur="addFocus = false" type="text" class="w-full py-2 pl-8 text-white placeholder-gray-400 bg-transparent focus:outline-none" :placeholder="`${add.income ? 'Einkommen' : 'Ausgabe'} hinzufügen`" >      
      <button @click="add.income = true" :class="['absolute p-1 transform -translate-y-1/2 transition-all duration-100 rounded-md shadow top-1/2 right-10 focus:outline-none', add.income ? 'ring bg-gray-600 ring-primary-600' : addFocus || add.value ? 'bg-gray-800' : 'bg-gray-900']">
        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>
      </button>
      <button @click="add.income = false" :class="['absolute p-1 transform -translate-y-1/2 transition-all duration-100 rounded-md shadow top-1/2 right-2 focus:outline-none', !add.income ? 'ring bg-gray-600 ring-primary-600' : addFocus || add.value ? 'bg-gray-800' : 'bg-gray-900']">
        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"></path></svg>
      </button>
    </div>

    <p class="my-4 text-xl font-semibold text-center text-gray-200 opacity-50">{{formatCurrency($store.getters['bills/total'])}}</p>

    <ul class="flex flex-col gap-2 mt-4 transition-all duration-300 transform">
      <li @click="select == record.id ? select = 0 : select = record.id" :class="['cursor-pointer px-4 transition-all duration-300 transform', select == record.id ? 'bg-gray-700 rounded-md shadow scale-y-110 scale-x-110' : '']" v-for="(record, n) in $store.getters['bills/all']" :key="record.id">
        <div :class="['flex items-center justify-center py-2 border-b', n == records.length -1 ? 'border-transparent' : 'border-gray-700']">
          {{fullDateFromTS(record.createdAt)}}
          <span class="ml-auto text-sm text-gray-400">{{`${record.income ? '+' : '-'} ${formatCurrency(record.value)}`}}</span>
        </div>
        <div :class="['flex flex-wrap items-center transition-all duration-200 origin-top transform _border-b', select == record.id ? 'mb-2 scale-y-100 h-auto opacity-100' : 'scale-y-0 h-0 opacity-0']">
          <svg v-if="record.cash" class="w-4 h-4 mr-2 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
          <svg v-else class="w-4 h-4 mr-2 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path></svg>
          <span class="text-xs text-gray-400">{{record.comment}}</span>
          <div class="flex-1"></div>
          <img :src="record.user.img" class="w-6 h-6 rounded-full">
          <div class="flex items-center justify-between w-full gap-4 py-2 mt-1">
            <button @click="doEdit(record.id)" class="flex items-center justify-center w-1/2 py-1 text-xs text-white rounded-md focus:outline-none bg-primary-600 hover:bg-primary-500">
              <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
              Bearbeiten
            </button>
            <button @click="remove = true; removeID = record.id" class="flex items-center justify-center w-1/2 py-1 text-xs bg-red-600 rounded-md focus:outline-none">
              <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
              Löschen
            </button>
          </div>
        </div>
      </li>
    </ul>

    <modal :open="remove" @close="remove = false" :closeable="false">
      <h2 class="text-lg">Möchtest du den Eintrag wirklich löschen?</h2>
      <div class="flex items-center justify-end mt-8">
        <button @click="remove = false" class="flex-1 px-4 py-2 mr-4 text-sm bg-gray-700 rounded-md sm:flex-initial focus:outline-none">Abbrechen</button>
        <button @click="deleteRecord()" class="flex-1 px-4 py-2 text-sm bg-red-600 rounded-md sm:flex-initial focus:outline-none">Ja, löschen</button>
      </div>
    </modal>

    <modal :open="edit" @close="editSave()" :closeable="false">
      <template v-if="edit">
        <div class="flex items-center gap-4 mb-4">
          <h2 class="inline w-2/3 text-lg">{{fullDateFromTS(editRecord.createdAt)}}</h2>
          <input v-model="editRecord.value" type="text" class="w-1/3 px-2 py-1 text-right transition-all duration-100 transform bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring focus:ring-primary-600">
        </div>
        <input v-model="editRecord.comment" type="text" class="w-full px-2 py-1 transition-all duration-100 transform bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring focus:ring-primary-600" placeholder="Kommentar">
        <div class="flex items-center justify-center w-full mt-4">
          <button @click="editRecord.cash = true" :class="['flex items-center justify-center py-1 text-sm border focus:outline-none rounded-l-md w-1/2 transition-all transform duration-100', editRecord.cash ? 'bg-gray-700 border-transparent' : 'bg-gray-900 bg-opacity-50 border-gray-700 border-opacity-50']">
            <svg class="w-5 h-5 mb-px mr-2 opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
            Bargeld
          </button>
          <button @click="editRecord.cash = false" :class="['flex items-center justify-center py-1 text-sm border focus:outline-none rounded-r-md w-1/2 transition-all transform duration-100', !editRecord.cash ? 'bg-gray-700 border-transparent' : 'bg-gray-900 bg-opacity-50 border-gray-700 border-opacity-50']">
            <svg class="w-5 h-5 mb-px mr-2 opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path></svg>
            Karte / Konto
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import modal from '@/components/modal'
import dateCalc from '@/mixins/dateCalc'
import currency from '@/mixins/currency'

export default {
  components: { modal },
  mixins: [ dateCalc, currency ],
  data: () =>  ({
    addFocus: false,
    add: {
      value: null,
      income: false,
      cash: true,
      comment: ''
    },
    select: 0,
    remove: false,
    removeID: null,
    edit: false,
    editRecord: null,
  }),
  computed: {
    records: vm => vm.$store.getters['bills/all'].sort((a, b) => b.createdAt - a.createdAt),
  },
  methods: {
    async addRecord() {
      if(!this.add.value) return
      this.$store.dispatch('bills/addRecord', this.add).then(id => {
        // console.log('added id:', id)
        this.add = {
          value: null,
          income: false,
          cash: true,
          comment: ''
        }
        this.doEdit(id)
      })
    },
    async deleteRecord() {
      this.remove = false
      this.select = 0
      this.$store.dispatch('bills/delete', this.removeID)
      this.removeID = null
    },
    doEdit(id) {
      this.editRecord = this.$store.getters['bills/single'](id)
      // console.log(this.editRecord)
      this.edit = true
    },
    async editSave() {
      let record = this.editRecord  
      this.$store.dispatch('bills/update', { id: this.editRecord.id, data: record }).then(() => {
        this.edit = false
      })
    }
  }
}
</script>